/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    blockquote: "blockquote",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "About"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Cloud Consultant focusing on complex solutions for customers in the CEE region. Came from aerospace 🚀. Ready to exceed challenging tasks and goals."), "\n"), "\n", React.createElement(_components.p, null, "I'm looking for opportunities to collaborate with partners, not just work for them. My ultimate goal is to solve real business problems together in a way, beneficial for both sides."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
