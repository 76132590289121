/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p",
    br: "br"
  }, _provideComponents(), props.components), {ProjectCard} = _components;
  if (!ProjectCard) _missingMdxReference("ProjectCard", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Experience"), "\n", React.createElement(ProjectCard, {
    title: "ORBIT",
    link: "https://orbit.cz/",
    bg: "linear-gradient(to right, #EF6C33 0%, #EF6C33 100%)"
  }, React.createElement(_components.p, null, "Solution design and implementation for AWS Cloud")), "\n", React.createElement(ProjectCard, {
    title: "Swipelux",
    link: "https://swipelux.com/",
    bg: "linear-gradient(to right, #662D8C 0%, #ED1E79 100%)"
  }, React.createElement(_components.p, null, "Cloud infrastructure management and maintenance (AWS) ", React.createElement(_components.br), "\n", "Security of the infrastructure (Ubuntu, CentOS) ", React.createElement(_components.br), "\n", "Infrastructure as a code IaC (Terraform) ", React.createElement(_components.br), "\n", "Management of CI/CD processes (Gilab CI/CD, Github Actions) ", React.createElement(_components.br), "\n", "Reliability and availability of applications (Java, NodeJS) ", React.createElement(_components.br), "\n", "Kubernetes cluster management (Rancher) ", React.createElement(_components.br), "\n", "Application management in Kubernetes (Rancher, EKS) ", React.createElement(_components.br), "\n", "Documentation management")), "\n", React.createElement(ProjectCard, {
    title: "Smartlook",
    link: "https://smartlook.com/",
    bg: "linear-gradient(to right, #D4145A 0%, #FBB03B 100%)"
  }, React.createElement(_components.p, null, "Cloud infrastructure management and maintenance (AWS) ", React.createElement(_components.br), "\n", "Security of the infrastructure (Ubuntu) ", React.createElement(_components.br), "\n", "Infrastructure as a code IaC (Terraform) ", React.createElement(_components.br), "\n", "Management of CI/CD processes (Github Actions) ", React.createElement(_components.br), "\n", "Reliability and availability of applications (NodeJS) ", React.createElement(_components.br), "\n", "Application management in Kubernetes (EKS) ", React.createElement(_components.br), "\n", "Documentation management")), "\n", React.createElement(ProjectCard, {
    title: "Absa Technology",
    link: "https://www.absa.africa/absaafrica/",
    bg: "linear-gradient(to right, #009245 0%, #FCEE21 100%)"
  }, React.createElement(_components.p, null, "Reliability of application log monitoring (ELK Stack) ", React.createElement(_components.br), "\n", "Migration of infrastructure from on-premises to cloud environment (AWS) ", React.createElement(_components.br), "\n", "Infrastructure as a code IaC (Terraform) ", React.createElement(_components.br), "\n", "Management of CI/CD processes (Github Actions, Azure Pipelines, AWS CodeBuild) ", React.createElement(_components.br), "\n", "Shell scripting (bash)")), "\n", React.createElement(ProjectCard, {
    title: "Commerzbank",
    link: "https://www.commerzbank.com/",
    bg: "linear-gradient(to right, #D585FF 0%, #00FFEE 100%)"
  }, React.createElement(_components.p, null, "Administration and maintenance of Commerzbank systems related to AML and Regulatory Requirement (AOP application) ", React.createElement(_components.br), "\n", "Semi-Automated and automated deployment of applications (Java apps running on RedHat servers) ", React.createElement(_components.br), "\n", "Ensure readiness/health-state of production and testing environments using Dynatrace, Kibana ", React.createElement(_components.br), "\n", "Job automation, scripts writing (sql, python, bash)")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
